export const segLoginUrl = () => {
    const endpoint = 'login'
    const url = `/${endpoint}`
    return url
}

export const segRenewUrl = () => {
    const endpoint = 'renew'
    const url = `/${endpoint}`
    return url
}

export const segConnUrl = () => {
    const endpoint = 'conn'
    const url = `/${endpoint}`
    return url
}

export const segCtoTokenUrl = () => {
    const endpoint = 'cto/token'
    const url = `/${endpoint}`
    return url
}

export const segTnaTokenUrl = () => {
    const endpoint = 'tna/token'
    const url = `/${endpoint}`
    return url
}

export const usuSignUpUrl = () => {
    const endpoint = 'usu/signUp'
    const url = `/${endpoint}`
    return url
}

export const usuChangePwdUrl = () => {
    const endpoint = 'usu/changePwd'
    const url = `/${endpoint}`
    return url
}

export const usuSendCodeUrl = () => {
    const endpoint = 'usu/sendCode'
    const url = `/${endpoint}`
    return url
}

export const usuEmsrRcptUrl = (resource) => {
    const endpoint = 'usu/emsrRcpt'
    const url = `/${endpoint}/${resource}`
    return url
}

export const usuGetNumUrl = (userID) => {
    const endpoint = 'usu'
    const url = `/${endpoint}?userID=${userID}`
    return url
}

export const usuGetCurrUrl = () => {
    const endpoint = 'usu/current'
    const url = `/${endpoint}`
    return url
}

export const accRegistrarUrl = () => {
    const endpoint = 'acc'
    const url = `/${endpoint}`
    return url
}

export const accEliminarUrl = (id) => {
    const endpoint = 'acc'
    const url = `/${endpoint}/${id}`
    return url
}

export const accAsignarUrl = (code) => {
    const endpoint = 'acc'
    const url = `/${endpoint}/${code}`
    return url
}

export const accGetBPagUrl = (emsrNumDoc, grupo, fechaIni, fechaFin, access, rol, rcptNumDoc, page, pageSize) => {
    const endpoint = 'acc/busq'
    const url = `/${endpoint}?emsrNumDoc=${emsrNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&access=${access}&rol=${rol}&rcptNumDoc=${rcptNumDoc}&page=${page}&pageSize=${pageSize}`
    return url
}

export const stdGetTipoUrl = (tipo) => {
    const endpoint = 'std'
    const url = `/${endpoint}?tipo=${tipo}`
    return url
}

export const catGetCodUrl = (codigo) => {
    const endpoint = 'cat'
    const url = `/${endpoint}?codigo=${codigo}`
    return url
}

export const rolGetUserAllUrl = () => {
    const endpoint = 'rol/userAll'
    const url = `/${endpoint}`
    return url
}

export const rolGetUserPagUrl = () => {
    const endpoint = 'rol/userPag'
    const url = `/${endpoint}`
    return url
}

export const bllRegistrarUrl = () => {
    const endpoint = 'bill'
    const url = `/${endpoint}`
    return url
}

export const bllEliminarUrl = (id) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}`
    return url
}

export const bllAdjuntarUrl = (id, line = null, send = null) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}/adjuntar` + (line !== null && line !== undefined ? `/${line}` : '') + (send ? `/send` : '')
    return url
}

export const bllAnularUrl = (id) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}/anular`
    return url
}

export const bllVerificarUrl = (id) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}/verificar`
    return url
}

export const bllAceptarUrl = (id) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}/aceptar`
    return url
}

export const bllRecuperarUrl = (id, fileType = null, line = null) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}/recuperar` + (fileType ? `/${fileType}/data` : '') + (line !== null && line !== undefined ? `/${line}` : '')
    return url
}

export const bllGetIdUrl = (id) => {
    const endpoint = 'bill'
    const url = `/${endpoint}/${id}`
    return url
}

export const bllGetNumUrl = (emsrNumDoc, numCP, pendiente) => {
    const endpoint = 'bill'
    const url = `/${endpoint}?emsrNumDoc=${emsrNumDoc}&numCP=${numCP}&pendiente=${pendiente.toString()}`
    return url
}

export const bllGetConUrl = (emsrNumDoc, grupo, fechaIni, fechaFin, pendiente, estado, tipoComprobanteID, monedaISO, rcptNumDoc, resource, page, pageSize) => {
    const endpoint = 'bill/consultar'
    const url = `/${endpoint}?emsrNumDoc=${emsrNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&pendiente=${pendiente.toString()}&estado=${estado}&tipoComprobanteID=${tipoComprobanteID}&monedaISO=${monedaISO}&rcptNumDoc=${rcptNumDoc}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

/** CALISTO ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** **/

export const cpeDataFVUrl = () => {
    const endpoint = 'cpe/dataFV'
    const url = `/${endpoint}`
    return url
}

export const cpeValidateUrl = () => {
    const endpoint = 'cpe/validate'
    const url = `/${endpoint}`
    return url
}

export const storageRUCUrl = (numDoc) => {
    const endpoint = 'storage/ruc'
    const url = `/${endpoint}?numDoc=${numDoc}`
    return url
}

export const storageTCambioUrl = (monedaID, fecha) => {
    const endpoint = 'storage/tipoCambio'
    const url = `/${endpoint}?monedaID=${monedaID}&fecha=${fecha}T00:00:00`
    return url
}

export const webPageDNIUrl = (numDoc) => {
    const endpoint = 'webPage/dni'
    const url = `/${endpoint}?numDoc=${numDoc}`
    return url
}

/** Titania ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** **/

export const billDataUrl = (tipoComprobanteID) => {
    const endpoint = 'bill/data'
    const url = `/${endpoint}/${tipoComprobanteID}`
    return url
}