import { getError } from "./apiUtil"
import { axiosDne } from "./axiosDne"

export const axiosDneGet = async (url) => {
    let err = null
    let dat = null
    try {
        const resp = await axiosDne.get(url)
        dat = resp?.data
    } catch (error) {
        err = getError(error)
        console.log(error)
    }
    return [dat, err]
}

export const axiosDnePost = async (url, data) => {
    let err = null
    let dat = null
    try {
        const resp = await axiosDne.post(url, JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
        })
        dat = resp?.data
    } catch (error) {
        err = getError(error)
        console.log(error)
    }
    return [dat, err]
}