export const msgType = {
    apiError: 'Oops, algo salió mal, intente más tarde!',

    selectEmisor: 'Seleccione un emisor.',
    selectGrupo: 'Seleccione grupo.',
    selectFecha: 'Seleccione una fecha de inicio y fin.',
    selectComprobante: 'Seleccione un comprobante.',

    inputNumCP: 'Ingrese un número de comprobante.',
    inputMotivoAce: 'Ingrese un motivo de aceptación.',
    inputMotivoAnu: 'Ingrese un motivo de anulación.',

    registroNoOne: 'Seleccione solo un registro.',

    fileKoZIP: 'El archivo debe ser ZIP.',
    fileKoPeso: 'Peso de archivo no permitido.',

    comprobanteNoReemp: 'No marcado para reemplazar.',
    comprobanteNoVerif: 'No se logró verificar.',
    comprobanteRegistrar: 'Comprobante registrado:',
    comprobanteEliminar: 'Comprobante eliminado.',
    comprobanteVerificar: 'Comprobante verificado.',
    comprobanteAceptar: 'Comprobante aceptado.',
    comprobanteAnular: 'Comprobante anulado.',

    // Modificar lo posterior

    loNoEmail: 'Ingrese un correo electrónico.',
    loNoPwd: 'Ingrese una contraseña.',
    loNoUser: 'Ingrese un nombre de usuario.',
    loNoCode: 'Ingrese el código de verificación.',
    loValEmail: 'Correo electrónico inválido.',
    loValPwd: 'Contraseña inválida.',
    loValUser: 'Nombre de usuario inválido.',
    loIdleTime: 'La sesión expiro.',
    loNewToken: 'Oops, sin token!',
    loOKCode: 'Código de verificación enviado, revise su correo.',
    loOKSignUp: 'Cuenta registrada, ya puede iniciar sesión.',
    loOKChangePwd: 'Contraseña actualizada, ya puede iniciar sesión.',

    dabrdNoCodigo: 'Ingrese un código.',
    dabrdAssignCodeOK: 'Código de acceso asignado.',
    dabrdNoRcpt: 'Seleccione un receptor.',
    dabrdNoGroup: 'Seleccione un grupo.',
    dabrdNoEmsr: 'Seleccione un emisor.',
    dabrdNoAnho: 'Seleccione un año.',

    accCodeNoEmsr: 'Seleccione un emisor.',
    accCodeNoGroup: 'Seleccione un grupo.',
    accCodeNoFecha: 'Seleccione una fecha de inicio y fin.',
    accCodeNoRol: 'Seleccione un rol.',
    accCodeBloqueado: 'Acceso bloqueado.',
    accCodeNoRUC: 'Ingrese un número de RUC.',
    accCodeNoUsuario: 'Ingrese una cuenta de usuario.',
    accCodeDelOK: 'Código de acceso eliminado.',
    accCodeRegOK: 'Código de acceso registrado.',
    accCodeMsgCode: 'Código copiado!',
    accCodeMsgUser1: 'Usuario registrado!',
    accCodeMsgUser2: 'Usuario no registrado!',
}