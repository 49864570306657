import './NewCpe.css'

import { SyncOutlined, CloudUploadOutlined, CloudDownloadOutlined, QuestionCircleOutlined, DeleteFilled } from '@ant-design/icons'
import { Checkbox, Popconfirm, Spin, Table, Tabs } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosDne } from '../../../../hooks/useAxiosDne'
import { bllAdjuntarUrl, bllGetIdUrl, bllRecuperarUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyYellow } from '../../../Msg/NotifyYellow'
import { configType } from '../../../../types/configType'

var jszip = require('jszip')

export const NewCpe = forwardRef((props, ref) => {
    const { selComprobante, updateCpe, tabKey } = props

    const { axiosDneGet, axiosDnePut, axiosDneDelete } = useAxiosDne()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [loadFile, setLoadFile] = useState(false)
    const [adjuntos, setAdjuntos] = useState([])
    const [enviarCpe, setEnviarCpe] = useState(true)
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [activeTabKey, setActiveTabKey] = useState("1")

    useEffect(() => {
        setAdjuntos(selComprobante?.adjuntos ? selComprobante.adjuntos.map(p => ({ ...p, key: p.dataOrder })) : [])
    }, [selComprobante])

    useImperativeHandle(
        ref,
        () => ({
            showNotify(err, type) {
                showNotifyCpe(err, type)
            }
        })
    )

    const refreshCpe = async (cpe) => {
        const url = bllGetIdUrl(cpe.cpe_id)
        const [data, err] = await axiosDneGet(url)

        if (err)
            console.log(`Comprobante: ${err.message}`)
        const ncpe = data?.success ? data.data : null
        return ncpe
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload")
        if (input)
            input.value = null
    }

    const showNotifyCpe = (err, type) => {
        if (type === notifyType.warning)
            notifyYellowRef.current.handleOpen(err, type)
        else if (type === notifyType.error)
            notifyRedRef.current.handleOpen(err, type)
        else if (type === notifyType.success)
            notifyGreenRef.current.handleOpen(err, type)
    }

    const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

    const handleUpFile = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadFile(true)

        const formData = new FormData()
        formData.append("", file)

        const url = bllAdjuntarUrl(selComprobante.cpe_id, null, enviarCpe)
        const [data_api, err_api] = await axiosDnePut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            const ncpe = await refreshCpe(selComprobante)
            if (ncpe && updateCpe)
                updateCpe(ncpe)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        clearFile()
        setLoadFile(false)
        setLastAccess()
    }

    const handleDelFile = async (record) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = bllAdjuntarUrl(selComprobante.cpe_id, record.dataOrder)
        const [data_api, err_api] = await axiosDneDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            const ncpe = await refreshCpe(selComprobante)
            if (ncpe && updateCpe)
                updateCpe(ncpe)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = record.fileType ? bllRecuperarUrl(selComprobante.cpe_id, record.fileType, null) : bllRecuperarUrl(selComprobante.cpe_id, null, record.dataOrder)
        const [data_api, err_api] = await axiosDneGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <button
                            className='text-blue-600 text-left'
                            disabled={record.dataOrder === currentLineFI}
                            onClick={() => handleDownFile(record, false)}
                        >
                            {`${record.fileName}`}
                        </button>
                        {
                            record.fileType &&
                            <span>{`- ${record.fileType}`}</span>
                        }
                    </div>
                    <div className='flex justify-end items-center'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <>
                                <button onClick={() => handleDownFile(record, true)}>
                                    <CloudDownloadOutlined />
                                </button>
                                {
                                    !record.fileType &&
                                    <Popconfirm placement="left"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title={`¿Confirma la operación?`}
                                        onConfirm={() => handleDelFile(record)}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <button className='ml-2'>
                                            <DeleteFilled className='text-red-400' />
                                        </button>
                                    </Popconfirm>
                                }
                            </>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='ml-2' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            <div className='ant-tabs-dione ant-common-dione'>
                <Tabs
                    defaultActiveKey="1"
                    hidden={false}
                    activeKey={activeTabKey}
                    onChange={handleActiveTabKey}
                    items={[
                        {
                            label: `Adjunto`,
                            key: '1',
                            disabled: false,
                            children:
                                <>
                                    {
                                        adjuntos.length > 0 &&
                                        <div className='mb-3 ant-table-dione'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={adjuntos}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                    }
                                    <div className='flex items-center space-x-2'>
                                        <label
                                            htmlFor="file-upload"
                                            className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                                        >
                                            <CloudUploadOutlined className="mr-1" />
                                            <span>Subir archivo</span>
                                            <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                                onChange={handleUpFile}
                                                disabled={loadFile}
                                            />
                                        </label>
                                        <Checkbox className='text-xs' checked={enviarCpe} onChange={e => setEnviarCpe(e.target.checked)}>Enviar por correo electrónico</Checkbox>
                                        {
                                            loadFile &&
                                            <div className='flex items-center mr-2 text-indigo-600'>
                                                <SyncOutlined spin />
                                            </div>
                                        }
                                    </div>
                                </>
                        }
                    ]}
                />
            </div>
        </>
    )
})