import {
    HomeIcon, NewspaperIcon, Cog6ToothIcon, UserGroupIcon, DocumentMagnifyingGlassIcon,
    RectangleStackIcon
} from '@heroicons/react/24/outline'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { Dashboard } from '../pages/private/Dashboard'
import { FileViewer } from '../pages/private/FileViewer'
import { ConsultarCPE } from '../pages/private/ems/ConsultarCPE'
import { Panel } from '../pages/private/Panel'
import { CodigoAcceso } from '../pages/private/cfg/CodigoAcceso'
import { AdministrarCPE } from '../pages/private/ems/AdministrarCPE'

const numPage = 20
const cfgLine = 1
const emsLine = 2

export const privatePages = [
    { path: '/ems/consultarcpe', component: ConsultarCPE, group: 'ems', code: 'em_concpe', pageName: 'Consultar comprobante', icon: DocumentMagnifyingGlassIcon, order: emsLine * numPage + 2 },
    { path: '/ems/registrarcpe', component: AdministrarCPE, group: 'ems', code: 'em_admcpe', pageName: 'Administrar comprobante', icon: RectangleStackIcon, order: emsLine * numPage + 1 },
    { path: '/cfg/codeaccess', component: CodigoAcceso, group: 'cfg', code: 'accesscode', pageName: 'Código de acceso', icon: UserGroupIcon, order: cfgLine * numPage + 1 },
    { path: '/dashboard', component: Dashboard, group: null, code: null, pageName: 'Dashboard', icon: HomeIcon, order: 1 },
    { path: '/', component: Dashboard, group: null, code: null, pageName: 'Dashboard', icon: null, order: null },
]

export const privateGroups = [
    { group: 'ems', code: null, pageName: 'Emisión electrónica', icon: NewspaperIcon, order: emsLine },
    { group: 'cfg', code: null, pageName: 'Configuración', icon: Cog6ToothIcon, order: cfgLine },
]

export const PrivateRouter = () => {
    const { auth } = useAuth()

    return (
        <>
            <Routes>
                {
                    privatePages.filter(page => !page.code || (auth.ropag && auth.ropag.some(rp => rp === page.code))).map((page, index) => (
                        <Route key={index} path={page.path} element={<Panel page={page}><page.component page={page} /></Panel>} />
                    ))
                }
                <Route path='/file' element={<FileViewer />} />
                <Route path='*' element={<Navigate to='/oops' />} />
            </Routes>
        </>
    )
}

