import { SyncOutlined, AuditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { accAsignarUrl, rolGetUserPagUrl } from '../../api/apiUrl'
import { AppFooter } from '../../components/AppFooter'
import { NotifyGreen } from '../../components/Msg/NotifyGreen'
import { NotifyRed } from '../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../components/Msg/NotifyYellow'
import { useAuth } from '../../hooks/useAuth'
import { useAxiosDne } from '../../hooks/useAxiosDne'
import { useForm } from '../../hooks/useForm'
import { msgType } from '../../types/msgType'
import { notifyType } from '../../types/notifyType'
import { setLastAccess } from '../../utils/lstorageUtil'

import './Dashboard.css'

export const Dashboard = () => {
  const { axiosDneGet, axiosDnePut } = useAxiosDne()
  const { setAuth } = useAuth()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadAsignar, setLoadAsignar] = useState(false)
  const [{ accessCode }, handleInputChange, resetInputData] = useForm({ accessCode: '' })

  useEffect(() => {
    setDisablePage(loadAsignar)
  }, [loadAsignar])

  const handleAssignCode = async () => {
    let err = null
    let data = null

    if (!accessCode)
      err = {
        message: msgType.dabrdNoCodigo,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadAsignar(true)

    let url = accAsignarUrl(accessCode)
    const [data_api, err_api] = await axiosDnePut(url, null)
    data = data_api
    err = err_api

    if (data) {
      resetInputData()
      notifyGreenRef.current.handleOpen({
        message: msgType.dabrdAssignCodeOK,
        oops: false
      }, notifyType.success)

      url = rolGetUserPagUrl()
      const [data_rol, err_rol] = await axiosDneGet(url)
      err = err_rol

      if (data_rol && data_rol.success) {
        const { resource } = data_rol
        setAuth(prev => ({ ...prev, ropag: resource }))
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadAsignar(false)
    setLastAccess()
  }

  return (
    <div className="bg-white flex flex-col flex-grow">
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      <div className='flex-grow p-3'>
        <p className="text-xs text-gray-700 mb-2">
          Ingresa un código de acceso para poder utilizar el sistema.
        </p>
        <div className="flex">
          <span className="inline-flex items-center p-1 text-xs rounded-l rounded-r-none border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
            CODIGO
          </span>
          <input
            type="text"
            name="accessCode"
            id="accessCode"
            autoComplete='accessCode'
            className="block text-xs pl-2 mr-1.5 w-28 rounded-l-none rounded-r border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            value={accessCode}
            onChange={handleInputChange}
            readOnly={disablePage}
            maxLength='8'
          />
          {
            !loadAsignar &&
            <button
              className="flex items-center relative rounded-md bg-white font-medium text-indigo-600"
              disabled={disablePage}
              onClick={handleAssignCode}
            >
              <Tooltip title="Asignar código" placement='right'>
                <AuditOutlined aria-hidden="true" />
              </Tooltip>
            </button>
          }
          {
            loadAsignar &&
            <div className='flex items-center text-indigo-600'>
              <SyncOutlined spin />
            </div>
          }
        </div>

      </div>
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}
