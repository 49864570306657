import './DetalleCpe.css'

import { CloudDownloadOutlined } from '@ant-design/icons'
import { Input, Spin, Table, Tabs } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosDne } from '../../../hooks/useAxiosDne'
import { bllGetIdUrl, bllRecuperarUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyYellow } from '../../Msg/NotifyYellow'
import { NotifyRed } from '../../Msg/NotifyRed'
import { NotifyGreen } from '../../Msg/NotifyGreen'

var jszip = require('jszip')

export const DetalleCpe = forwardRef((props, ref) => {
  const { selEmisor, selComprobante: vwComprobante, dbComprobante, tabKey, setObservacion } = props

  const { axiosDneGet } = useAxiosDne()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [loadPage, setLoadPage] = useState(true)
  const [loadGetCpe, setLoadGetCpe] = useState(false)
  const [camposCpe, setCamposCpe] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [estados, setEstados] = useState([])
  const [selComprobante, setSelComprobante] = useState(null)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")

  useEffect(() => {
    setLoadPage(loadGetCpe)
  }, [loadGetCpe])

  useEffect(() => {
    setSelComprobante(vwComprobante)
  }, [vwComprobante])

  useEffect(() => {
    setObservacion && setObservacion(null)
  }, [setObservacion])

  useEffect(() => {
    const getCampo = (ems, cpe) => {
      const campos_1 = [
        { label: 'Emisor', value: `${ems.ruc} - ${ems.name}` },
        { label: 'Grupo', value: cpe.grupo },
        { label: 'Tipo de comprobante', value: cpe.tipoComprobante },
        { label: 'Nº de comprobante', value: `${cpe.serie} - ${cpe.numero}` },
        { label: 'Receptor', value: cpe.receptor ? `${cpe.receptor.numeroDoc} - ${cpe.receptor.nomRazSoc}` : null },
        { label: 'Fecha de emisión', value: formatDate(cpe.fechaEmision) },
        { label: 'Moneda', value: cpe.monedaISO },
        { label: 'Importe total', value: `${formatAmount(cpe.total)} ` },
        { label: 'Estado', value: cpe.estado, detail: cpe.reemplazar ? 'reemplazar' : null },
        { label: 'Correo electrónico', value: cpe.email ? cpe.email.join('; ') : null },
      ]
      const campos_2 = cpe.observ ? cpe.observ.map(p => (
        { label: `Observación`, value: p }
      )) : null
      const campos_3 = [
        { label: 'Fecha de registro', value: formatTime(cpe.fechaReg) },
        { label: 'Usuario de registro', value: cpe.usuarioReg },
      ]
      const campos = [...campos_1]
      if (campos_2)
        campos.push(...campos_2)
      campos.push(...campos_3)
      return campos.filter(p => p.value)
    }

    setCamposCpe(selEmisor && selComprobante ? getCampo(selEmisor, selComprobante) : [])
  }, [selEmisor, selComprobante])

  useEffect(() => {
    if (selComprobante) {
      if (selComprobante.adjuntos)
        setAdjuntos(selComprobante.adjuntos.map((att, index) => ({
          ...att,
          key: index
        })))
      if (selComprobante.audit)
        setEstados(selComprobante.audit.map((sto, index) => ({
          ...sto,
          key: index
        })))
    }
  }, [selComprobante])

  useEffect(() => {
    let isMounted = true

    const getCpe = async () => {
      isMounted && setLoadGetCpe(true)

      const url = bllGetIdUrl(dbComprobante.cpe_id)
      const [data, err] = await axiosDneGet(url)

      if (data) {
        const { success, data: cpe } = data
        if (success)
          isMounted && setSelComprobante({ ...cpe, key: cpe.cpe_id })
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadGetCpe(false)
    }

    if (dbComprobante)
      getCpe()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [dbComprobante])

  useImperativeHandle(
    ref,
    () => ({
      showNotify(err, type) {
        showNotifyCpe(err, type)
      }
    })
  )

  const showNotifyCpe = (err, type) => {
    if (type === notifyType.warning)
      notifyYellowRef.current.handleOpen(err, type)
    else if (type === notifyType.error)
      notifyRedRef.current.handleOpen(err, type)
    else if (type === notifyType.success)
      notifyGreenRef.current.handleOpen(err, type)
  }

  const getClassName = (index, lista) => {
    if (index === 0)
      return 'sm:flex'
    else if (index === lista.length - 1)
      return 'py-0.5 sm:flex'
    else
      return 'pt-0.5 sm:flex'
  }

  const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = record.fileType ? bllRecuperarUrl(selComprobante.cpe_id, record.fileType, null) : bllRecuperarUrl(selComprobante.cpe_id, null, record.dataOrder)
    const [data_api, err_api] = await axiosDneGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err) {
      console.log(err)
      notifyRedRef.current.handleOpen(err, notifyType.error)
    }

    setCurrentLineFI(null)
    setLastAccess()
  }

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
            {
              record.fileType &&
              <span>{`- ${record.fileType}`}</span>
            }
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  const stColumns = [
    {
      title: 'Estado',
      dataIndex: 'estadoName',
      width: 120,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaIni',
      width: 160,
      render: (_, record) => <span>{formatTime(record.fechaIni)}</span>
    },
    {
      title: 'Usuario',
      dataIndex: 'userID',
    },
  ]

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin size='large' />
          </div > :
          <div className='ant-tabs-dione'>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeTabKey}
              onChange={handleActiveTabKey}
              items={[
                {
                  label: `Data`,
                  key: '1',
                  children:
                    <>
                      <div>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposCpe.map(({ label, value, detail }, index) => (
                              <div className={getClassName(index, camposCpe)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="flex items-center space-x-1.5 text-gray-900">
                                    <span>{value}</span>
                                    {
                                      detail &&
                                      <span className='text-xs text-red-600'>{detail}</span>
                                    }
                                  </div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        setObservacion && selComprobante &&
                        < div className='mt-3'>
                          <Input.TextArea
                            maxLength={500}
                            showCount
                            placeholder={`Ingrese un motivo de ${selComprobante.cpeDoc ? 'anulación' : 'aceptación'}`}
                            onChange={(e) => setObservacion(e.target.value)}
                          />
                        </div>
                      }
                    </>
                },
                {
                  label: `Adjunto`,
                  key: '2',
                  disabled: adjuntos.length === 0,
                  children:
                    <>
                      {
                        adjuntos.length > 0 &&
                        <div className='mb-3 ant-table-dione'>
                          <Table
                            columns={fiColumns}
                            dataSource={adjuntos}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </>
                },
                {
                  label: `Estado`,
                  key: '3',
                  disabled: estados.length === 0,
                  children:
                    <div className='mb-3 ant-table-dione'>
                      <Table
                        columns={stColumns}
                        dataSource={estados}
                        pagination={false}
                        bordered
                        size='small'
                      />
                    </div>
                },
              ]}
            />
          </div >
      }
    </>
  )
})
