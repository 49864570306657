import { axiosDneGet } from "../api/apiAxios"
import { segRenewUrl } from "../api/apiUrl"
import { getAuth } from "../api/apiUtil"
import { useAuth } from "./useAuth"

export const useTokenDne = () => {
    const { setAuth } = useAuth()

    const refresh = async () => {
        const url = segRenewUrl()
        const [dat_api, err_api] = await axiosDneGet(url)
        const tokenDne = dat_api?.token
        if (!err_api)
            setAuth(prev => { return { ...prev, tokenDne } })
        else
            setAuth({ ...getAuth(false), message: err_api?.message })
        return tokenDne
    }

    return refresh
}